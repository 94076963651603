import * as actionTypes from "../actionTypes/profileActionTypes";

export const personalReducer = (
  state = {
    loading: false,
    loading_facial: false,
    profileCompleted: JSON.parse(localStorage.getItem("authRes"))
      ?.profile_completed,
    personalCompleted: JSON.parse(localStorage.getItem("authRes"))?.profile
      .personal_details_at,
    canTransact: JSON.parse(localStorage.getItem("authRes"))?.can_transact,
    face_verified: true,
    fetching_profile: true,
    transactionSettingsCompleted: JSON.parse(localStorage.getItem("authRes"))?.profile
      ?.transaction_details_at,
  },
  action
) => {
  switch (action.type) {
    case actionTypes.SUBMITTING_PERSONAL:
      return { ...state, loading: !state.loading };
    case actionTypes.LOADING_FACIAL_VERIFICATION:
      return { ...state, loading_facial: action.payload };
    case actionTypes.GETTING_PROFILE_DATA:
      return { ...state, fetching_profile: action.payload };
    case "PROFILE_COMPLETED":
      return { ...state, profileCompleted: action.payload };
    case "PERSONAL_COMPLETED":
      return { ...state, personalCompleted: action.payload };
    case "CAN_TRANSACT":
      return { ...state, canTransact: action.payload };
    case "TRANSACTION_SETTINGS_COMPLETED":
      return { ...state, transactionSettingsCompleted: action.payload };
    case actionTypes.SET_FACIAL_VERIFICATION_STATUS:
      return { ...state, face_verified: action.payload };
    default:
      return state;
  }
};

export const kycReducer = (
  state = {
    loading: false,
    kycCompleted: JSON.parse(localStorage.getItem("authRes"))?.profile
      .kyc_details_at
      ? true
      : false,
  },
  action
) => {
  switch (action.type) {
    case "KYC_COMPLETED":
      return { ...state, kycCompleted: action.payload };
    case actionTypes.SUBMITTING_KYC:
      return { ...state, loading: !state.loading };
    default:
      return state;
  }
};

export const addressReducer = (
  state = {
    loading: false,
    addresCompleted: JSON.parse(localStorage.getItem("authRes"))?.profile
      .address_details_at
      ? true
      : false,
  },
  action
) => {
  switch (action.type) {
    case "ADDRESS_COMPLETED":
      return { ...state, addresCompleted: action.payload };
    case actionTypes.SUBMITTING_ADDRESS:
      return { ...state, loading: !state.loading };
    default:
      return state;
  }
};

export const outBoundReducer = (
  state = {
    loading: false,
    getting_outbound_data: false,
    user_outbound_data: [],
  },
  action
) => {
  switch (action.type) {
    case actionTypes.SUBMITTING_OUTBOUND:
      return { ...state, loading: !state.loading };
    case actionTypes.GETTING_OUTBOUND_DATA:
      return { ...state, getting_outbound_data: !state.getting_outbound_data };
    case actionTypes.SET_OUTBOUND_DATA:
      return { ...state, user_outbound_data: action.payload };
    default:
      return state;
  }
};
