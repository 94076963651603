import React, { useEffect, useState, useRef } from "react";
import * as Yup from "yup";
import FormikError from "../../../components/partials/formikError";
import { Spin, Select } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { saveAddress } from "../../../store/actions/profileActions";
import {
  fetchStates,
  fetchRatedCountries,
} from "../../../store/actions/general";
import { Formik } from "formik";

const google = (window.google = window.google ? window.google : {});
export default function Address() {
  const dispatch = useDispatch();
  const { loading } = useSelector((state) => state.address);
  const { personalCompleted } = useSelector((state) => state.personal);
  const { msg } = useSelector((state) => state.error);
  const { ratedCountries, loadingRatedCountries } = useSelector(
    (state) => state.general
  );
  const { country } = JSON.parse(localStorage.getItem("authRes"));
  const { profile, state } = JSON.parse(localStorage.getItem("authRes"));
  const authRes = useSelector((state) => state.auth);
  const [myState, setMyStates] = useState([]);
  const [states, setStates] = useState([]);

  const [selCountry, setSelCountry] = useState("");

  // useEffect(() => {
  //   // if (!states) dispatch(fetchStates);
  //   dispatch(fetchStates);
  // }, [states, dispatch]);
  useEffect(() => {
    dispatch(fetchStates);
    dispatch(fetchRatedCountries);
    setSelCountry(profile.country_id);
  }, []);

  useEffect(() => {
  const storedStates = JSON.parse(localStorage.getItem("states"));
    setStates(storedStates ?? null);
  }, []);

  const schema = Yup.object({
    address: Yup.string("Enter address")
      .required("Address is required")
      .typeError("enter address"),
    street_no: Yup.string("Enter street_no")
      .required("Street number is required")
      .max(15, "Street number must conatin at most 15 characters")
      .typeError("Enter street no"),
    state: Yup.string("").required("select state/city"),
    street_name: Yup.string("enter street name")
      .required("Street name")
      .typeError("Enter street no"),
    suburb: Yup.string("Enter suburb")
      .required("Suburb is required")
      .typeError("Enter suburb"),
    unit_no: Yup.string("Enter uint no"),
    postal_code: Yup.string("Enter postal code")
      .required("Postal or zip code is required")
      .typeError("Enter postal code"),
  });

  const initialValues = {
    address: profile.address_line_1 ?? "",
    street_no: profile.street_no ?? "",
    street_name: profile.street_name ?? "",
    suburb: profile.suburb ?? "",
    unit_no: profile.unit_no ?? "",
    postal_code: profile.postcode ?? "",
    state: state?.id ?? "",
    country_id: profile.country_id,
  };
  const formRef = useRef();
  useEffect(() => {
    setMyStates(states?.filter((el) => el.country_id === profile.country_id));
  }, [states]);

  useEffect(() => {
    if (selCountry) {
      setMyStates(states?.filter((el) => el.country_id === selCountry));
      formRef?.current?.setValues({
        ...formRef.current.values,
        state: "",
      });
    }
  }, [selCountry]);

  useEffect(() => {
    try {
      const addressField = document.getElementById("address");
      let autocomplete = new google.maps.places.Autocomplete(addressField);

      autocomplete.addListener("place_changed", () => {
        formRef.current.setValues({
          ...formRef.current.values,
          address: "",
          street_no: "",
          street_name: "",
          suburb: "",
          unit_no: "",
          postal_code: "",
          // state: "",
        });

        const obj = {};

        let place = autocomplete.getPlace();

        for (const pl of place.address_components) {
          if (pl.types[0] === "subpremise") {
            obj["unit_no"] = pl.long_name;
          }

          if (pl.types[0] === "street_number") {
            obj["street_no"] = pl.long_name;
          }

          if (pl.types[0] === "route") {
            obj["street_name"] = pl.long_name;
          }

          if (pl.types[0] === "locality") {
            obj["suburb"] = pl.long_name;
          }

          if (pl.types[0] === "postal_code") {
            obj["postal_code"] = pl.long_name;
          }
        }
        formRef.current.setValues({
          ...formRef.current.values,
          address: addressField.value,
          street_no: obj.street_no ?? "",
          street_name: obj.street_name ?? "",
          suburb: obj.suburb ?? "",
          unit_no: obj.unit_no ?? "",
          postal_code: obj.postal_code ?? "",
          // state: obj.state ?? "",
          // country_id: country.id
        });
      });
    } catch (err) {
      console.log(err);
    }
  }, []);

  return (
    <div className="accordion-item border-bottom-0">
      <h2 className="accordion-header" id="flush-headingOne">
        <button
          className="accordion-button collapsed"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#flush-collapseTwo"
          disabled={personalCompleted ? false : true}
        >
          <div className="d-flex align-items-center">
            <div className="flex-shrink-0">
              <img src="../assets/images/icon/Group 781(1).png" alt />
            </div>
            <div className="flex-grow-1 ms-3">
              <h6 className="fw-bold">2. Address Information</h6>
              <p className="text-muted">
                Your address details{" "}
                
              </p>
            </div>
          </div>
        </button>
      </h2>
      {personalCompleted && (
        <div
          id="flush-collapseTwo"
          className="accordion-collapse collapse"
          aria-labelledby="flush-headingOne"
          data-bs-parent="#accordionFlushExample"
        >
          <div className="accordion-body border-bottom pt-1 pb-4">
            <Formik
              initialValues={initialValues}
              onSubmit={(values) => {
                dispatch(saveAddress({ ...values }));
              }}
              validationSchema={schema}
              innerRef={formRef}
            >
              {(props) => (
                <form onSubmit={props.handleSubmit} className="ms-lg-5">
                  <div className="row">
                    {/* Address */}
                    <div className="col-xl-10 col-md-12">
                      <div className="form-group mb-3">
                        <label className="mb-2">
                          Address <span className="text-red">*</span>
                        </label>
                        <input
                          type="text"
                          className="form-control min-height"
                          name="address"
                          id="address"
                          defaultValue={props.values.address}
                          onChange={props.handleChange}
                          onBlur={props.handleBlur}
                          autoComplete="off"
                        />
                        {props.touched.address && props.errors.address && (
                          <FormikError msg={props.errors.address} />
                        )}
                        <FormikError msg={msg?.address ?? ""} />
                      </div>
                    </div>
                    <div className="col-xl-5 col-md-6">
                      <div className="form-group mb-3">
                        <label className="mb-2">
                          Country<span className="text-red">*</span>
                        </label>
                        <Select
                          className="form-control min-height"
                          defaultValue={props.values.country_id}
                          disabled
                          onChange={(val) => {
                            props.setValues({
                              ...props.values,
                              country_id: val,
                            });
                            setSelCountry(val);
                          }}
                          loading={loadingRatedCountries}
                        >
                          {ratedCountries?.from_countries?.map((el) => {
                            return (
                              <Select.Option
                                key={el.id}
                                id={el.id}
                                value={el.id}
                              >
                                <span
                                  className={`flag-icon flag-icon-${el.iso2.toLowerCase()} flag-icon-squared`}
                                ></span>
                                {"\u00A0"}
                                {el.name}
                              </Select.Option>
                            );
                          })}
                        </Select>
                      </div>
                    </div>
                    {/* State/City */}
                    <div className="col-xl-5 col-md-6">
                      <div className="form-group mb-3">
                        <label className="mb-2">
                          State/City <span className="text-red">*</span>
                        </label>
                        <Select
                          className="form-control min-height"
                          defaultValue={props.values.state}
                          // value={props.values.state}
                          onChange={(value) => {
                            props.setValues({ ...props.values, state: value });
                          }}
                        >
                          {myState?.map((el) => (
                            <Select.Option value={el.id} key={el.id}>
                              {el.name}
                            </Select.Option>
                          ))}
                        </Select>
                        {props.touched.state && props.errors.state && (
                          <FormikError msg={props.errors.state} />
                        )}
                        <FormikError msg={msg?.state ?? ""} />
                      </div>
                    </div>
                    {/* Unit no */}
                    <div className="col-xl-5 col-md-6">
                      <div className="form-group mb-3">
                        <label className="mb-2">Unit no</label>
                        <input
                          type="text"
                          className="form-control min-height"
                          name="unit_no"
                          onChange={props.handleChange}
                          onBlur={props.handleBlur}
                          defaultValue={props.values.unit_no}
                        />
                        {props.touched.unit_no && props.errors.unit_no && (
                          <FormikError msg={props.errors.unit_no} />
                        )}
                        <FormikError msg={msg?.unit_no ?? ""} />
                      </div>
                    </div>
                    {/* Street Number */}
                    <div className="col-xl-5 col-md-6">
                      <div className="form-group mb-3">
                        <label className="mb-2">
                          Street Number <span className="text-red">*</span>
                        </label>
                        <input
                          type="text"
                          className="form-control min-height"
                          name="street_no"
                          onChange={props.handleChange}
                          onBlur={props.handleBlur}
                          defaultValue={props.values.street_no}
                        />
                        {props.touched.street_no && props.errors.street_no && (
                          <FormikError msg={props.errors.street_no} />
                        )}
                        <FormikError msg={msg?.street_no ?? ""} />
                      </div>
                    </div>
                    {/* Street Name */}
                    <div className="col-xl-5 col-md-6">
                      <div className="form-group mb-3">
                        <label className="mb-2">
                          Street Name <span className="text-red">*</span>
                        </label>
                        <input
                          type="text"
                          className="form-control min-height"
                          name="street_name"
                          onChange={props.handleChange}
                          onBlur={props.handleBlur}
                          defaultValue={props.values.street_name}
                        />
                        {props.touched.street_name &&
                          props.errors.street_name && (
                            <FormikError msg={props.errors.street_name} />
                          )}
                        <FormikError msg={msg?.street_name ?? ""} />
                      </div>
                    </div>
                    {/* Suburb */}
                    <div className="col-xl-5 col-md-6">
                      <div className="form-group mb-3">
                        <label className="mb-2">
                          Suburb <span className="text-red">*</span>
                        </label>
                        <input
                          type="text"
                          className="form-control min-height"
                          name="suburb"
                          onChange={props.handleChange}
                          onBlur={props.handleBlur}
                          defaultValue={props.values.suburb}
                        />
                        {props.touched.suburb && props.errors.suburb && (
                          <FormikError msg={props.errors.suburb} />
                        )}
                        <FormikError msg={msg?.suburb ?? ""} />
                      </div>
                    </div>
                    {/* Post/Zip Code */}
                    <div className="col-xl-5 col-md-6">
                      <div className="form-group mb-3">
                        <label className="mb-2">
                          Post/Zip Code <span className="text-red">*</span>
                        </label>
                        <input
                          type="text"
                          className="form-control min-height"
                          name="postal_code"
                          onChange={props.handleChange}
                          onBlur={props.handleBlur}
                          defaultValue={props.values.postal_code}
                        />
                        {props.touched.postal_code &&
                          props.errors.postal_code && (
                            <FormikError msg={props.errors.postal_code} />
                          )}
                        <FormikError msg={msg?.postal_code ?? ""} />
                      </div>
                    </div>
                    {/* Button */}
                    <div className="col-xl-10">
                      <div className="form-group text-end mb-3">
                        <button
                          className="btn btn-light px-5 min-height border"
                          disabled={loading}
                          type="submit"
                        >
                          Update {loading && <Spin />}
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              )}
            </Formik>
          </div>
        </div>
      )}
    </div>
  );
}
