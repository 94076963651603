import React from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";

export default function Finish() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const trxData = JSON.parse(localStorage.getItem("trx_initData"));

  return (
    <div className="container-fluid">
      <span className="d-block text-red text-center mb-4">
        For Bank transfers/PayID, your transaction would be processed upon
        payment confirmation in our bank account
      </span>
      <div className="row">
        <div className="col-lg-12">
          <div className="card mb-4 rounded border-0">
            <div className="card-body py-4 text-center">
              <h5 className="fw-bold text-success mb-4">
                Total payable amount: {localStorage.getItem("trx_sendVal")}{" "}
                {localStorage.getItem("trx_fromCountryCurency")}
              </h5>
              <h2 className="text-red fw-bold mb-3">IMPORTANT NOTICE!!!</h2>
              <ul>
                <p>Payment must come from sender's account only</p>
                <p className="">
                  Please transfer funds directly to your CosmoRemit unique PayID
                  or bank account as shown below.
                </p>

                <p className="mb-4">
                  Your transaction reference number is{" "}
                  <span className="text-red">
                    {trxData.for_payin.reference}
                  </span>
                </p>
              </ul>
            </div>
          </div>
        </div>
        {
          <div className="col-lg-12">
            <div className="card h-100 rounded border-0">
              <div className="card-body py-5 text-center">
                <p className="text-muted fw-bold">
                  <span className="text-capitalize">PayID</span>:{" "}
                  <span className="text-red">
                    {trxData?.for_payin?.transfer_details?.payID_email}
                  </span>
                </p>

                <div>
                  <h5 className="text-align-center ">Or</h5>
                </div>
                {trxData?.for_payin?.transfer_details?.bsb && (
                  <p className="text-muted fw-bold">
                    <span className="text-capitalize">BSB</span>:{" "}
                    <span className="text-red">
                      {trxData?.for_payin?.transfer_details?.bsb}
                    </span>
                  </p>
                )}
                <p className="text-muted fw-bold">
                  <span className="text-capitalize">Account Number</span>:{" "}
                  <span className="text-red">
                    {trxData?.for_payin?.transfer_details?.account_number}
                  </span>
                </p>
                {trxData?.for_payin?.transfer_details?.account_name && (
                  <p className="text-muted fw-bold">
                    <span className="text-capitalize">Account Name</span>:{" "}
                    <span className="text-red">
                      {trxData?.for_payin?.transfer_details?.account_name}
                    </span>
                  </p>
                )}

                {/* {Object.entries(trxData?.for_payin?.transfer_details).map(
									([key, value]) => {
										return (
											<p className="text-muted fw-bold">
												<span className="text-capitalize">
													{key.replaceAll("_", " ")}
												</span>
												: <span className="text-red">{value}</span>
											</p>
										);
									}
								)} */}
              </div>
            </div>
          </div>
        }

        {/* <div className="col-lg-6">
          <div className="card h-100 rounded border-0">
            <div className="card-body py-4 text-center">
              <p className="fw-bold">Pay via PayID</p>
              <p className="text-muted mb-0">Pay ID email: <span className="text-red">{trxData.for_payin.transfer_details.pay_id_email}</span></p>
              <small>Our PayID is a secured and unique blockchain technology.</small>
            </div>
          </div>
        </div> */}
      </div>
      {/* <div className="py-4 text-center">
				<button
					style={{ cursor: "pointer" }}
					onClick={() => {
						dispatch({ type: "INITIALIZED", payload: false });
						navigate("/funding-receipt");
					}}
					className="min-height btn text-red px-5  border border-light bg-light"
					type="button"
				>
					View Receipt
				</button>
			</div> */}
    </div>
  );
}
