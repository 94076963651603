import React, { useCallback, useEffect, useRef, useState } from "react";
import Personal from "./personal";
import Address from "./address";
import TransactionSettings from "./transactionSettings";
import Kyc from "./kyc";
import { useDispatch, useSelector } from "react-redux";
import Webcam from "react-webcam";
import { Modal, Spin } from "antd";
import { facialVerification } from "../../../store/actions/profileActions";
import { urltoFile } from "../../../store/actions/utilityActions";
import * as notifications from "../../../components/notifications/notifications";
import ProfileInfo from "./profileInfo";
import { FaCopy } from "react-icons/fa6";

var moment = require("moment");

const videoConstraints = {
  width: 500,
  height: 500,
  facingMode: "user",
  disablePictureInPicture: true,
};
export default function Profile({}) {
  const [showVerifyModal, setShowVerifyModal] = useState(false);
  const [showPreview, setShowPreview] = useState(false);
  const [image, setImage] = useState("");
  const [user, setUser] = useState({});
  const [showKYCWarning, setShowKYCWarning] = useState(false);
  const [copied, setCopied] = useState(false);

  const { profileCompleted, loading_facial, face_verified } = useSelector(
    (state) => state.personal
  );

  const { kycCompleted } = useSelector((state) => state.kyc);

  const { dashboardMetas, fetchingDashboardMetas } = useSelector(
    (state) => state.transaction
  );

  const dispatch = useDispatch();
  useEffect(() => {
    const user = JSON.parse(localStorage.getItem("authRes") ?? {});
    setUser(user);
  }, [face_verified]);

  // useEffect(() => {
  //   notifications.info({
  //     title: "Info",
  //     msg: " Please be informed that editing and updating your profile will cause your profile to be unverified",
  //   });
  // }, []);

  const webcamRef = useRef(null);
  const capture = useCallback(() => {
    const imageSrc = webcamRef.current.getScreenshot();
    if (imageSrc) {
      setShowPreview(true);
      setImage(imageSrc);
    }
  }, [webcamRef]);
  const closeVerifyModal = () => {
    // webcamRef.current.
    setShowPreview(false);
    setShowVerifyModal(false);
  };
  const submitFacialVerification = async () => {
    if (image) {
      const file = urltoFile(image);
      dispatch(facialVerification(file, closeVerifyModal));
    }
  };
  const handleCopyClick = async (e) => {
    e.preventDefault();
    try {
      if (!copied) {
        await window.navigator.clipboard.writeText(`
              ${user?.referral_token}`);
        setCopied(true);
        notifications.success({ msg: "Copied to clipboard!" });
        setTimeout(() => {
          setCopied(false);
        }, 1000);
      }

      // alert("Copied to clipboard!");
    } catch (err) {
      notifications.error({ msg: "Copy to clipboard failed." });
    }
  };
  const imageProfileUrl = user?.profile?.profile_photo;

  return (
    <section className="py-4">
      {" "}
      <div className="container">
        {!face_verified && (
          <div className="d-flex  flex-column align-items-center ">
            <h5 className=" alert bg-danger rounded facial_warning text-center">
              To securely access other services, a facial verification step is
              required{" "}
              <span
                onClick={() => {
                  // if (kycCompleted) {
                    setShowVerifyModal(true);
                  // } else {
                  //   notifications.info({
                  //     title: "Info",
                  //     msg: "Please complete your KYC process in order to verify your facial identity.",
                  //   });
                  // }
                }}
                className=" verify_text underline"
              >
                Verify Now!
              </span>
            </h5>
          </div>
        )}
        {/* Profile date header */}
        <div className="card rounded mb-4 border-0">
          <div className="card-body px-lg-5 py-4">
            {/* profile image and name */}
            <div className="d-lg-flex mb-5 align-items-start">
              <div className="flex-shrink-0">
                <div className="profile-image">
                  {/* else if profile image is available */}
                  {imageProfileUrl !== "" ? (
                    <>
                      <img
                        src={imageProfileUrl}
                        className="profile-img-tag img-fluid"
                        alt=""
                      />
                    </>
                  ) : (
                    <>
                      <span className="profile-name-tag">AA</span>
                    </>
                  )}
                </div>
              </div>
              <div className="flex-grow-1 mt-3 mt-lg-0 ms-lg-4">
                <h6 className="fw-bolder mb-1">
                  {`${user?.profile?.firstname ?? ""} ${
                    user?.profile?.lastname ?? ""
                  }`}
                  <img
                    src="../assets/images/icon/icon-park_five-star-badge.png"
                    alt
                  />
                </h6>
                <p className="text-brown">Bronze Level Member</p>
                <p>
                  Pay ID - <span className="text-red">{user?.payid}</span>
                </p>
                <p>
                  Pay ID Account No. -{" "}
                  <span className="text-red">
                    {user?.payid_bank_account_number}
                  </span>
                </p>
                <a href="# " onClick={handleCopyClick} className="text-dark">
                  Referral Code -{" "}
                  <span className="text-red">
                    {" "}
                    {user?.referral_token} <FaCopy color="#e91908" />
                  </span>
                </a>
                <p className="text-dark">
                  Total Amount Sent -{" "}
                  <span className="text-red">{`${
                    dashboardMetas?.total_sent ?? 0
                  }`}</span>
                </p>
              </div>
            </div>
            {/* other profile details */}
            <div className="row justify-content-between">
              {/* member ID */}
              <div className="col-lg-2 col-sm-4">
                <p className="text-muted">Member ID</p>
                <p className="text-dark">{user?.profile?.member_id}</p>
              </div>

              {/* Email Address */}
              <div className="col-lg-2 col-sm-4">
                <p className="text-muted">Email Address</p>
                <p className="text-dark">{user?.email}</p>
              </div>
              {/* Phone Number */}
              <div className="col-lg-2 col-sm-4">
                <p className="text-muted">Phone Number</p>
                <p className="text-dark">{user?.profile?.mobile_phone}</p>
              </div>
              {/* Country Of Residence */}
              <div className="col-lg-2 col-sm-4">
                <p className="text-muted">Country Of Residence</p>
                <p className="text-dark">{user?.country?.name}</p>
              </div>
              {/* Member Since */}
              <div className="col-lg-2 col-sm-4">
                <p className="text-muted">Member Since</p>
                <p className="text-dark">
                  {moment(user?.created_at?.date).format("DD.MM.YYYY")}
                </p>
              </div>
            </div>
          </div>
        </div>
        {/* <h6 className="fw-bold ps-lg-4 mb-4">
          <span className="text-red">
            <i className="fas fa-exclamation-circle" /> &nbsp; Personal Details,
            Address Information and KYC
          </span>{" "}
          are all required before you can make transactions.
        </h6> */}

        {/* profile edit */}
        {!profileCompleted && (
          <div className="alert alert-danger text-dark rounded">
            <i className="fas fa-exclamation-circle text-danger" />
            &nbsp; Oops! It looks like your profile is incomplete. Please fill
            in all required information before proceeding with transactions.
            Thank you! e.g <p>(Review the following section)</p>
            <ol>
              {!user?.profile?.personal_details_at && (
                <li>Please complete personal details.</li>
              )}
              {!user?.profile?.address_details_at && (
                <li>Please complete address details.</li>
              )}
              {!user?.profile?.kyc_details_at && (
                <li>Please complete KYC details.</li>
              )}
              {!user?.profile?.transaction_details_at && (
                <li>Please complete transaction settings details.</li>
              )}
            </ol>
          </div>
        )}

        <div
          className="accordion rounded overflow-hidden accordion-flush mb-3"
          id="accordionFlushExample"
        >
          <div>
            <Personal />
            <Address />
            <TransactionSettings />
            <Kyc />
          </div>

          {/* Facial Verification Modal */}
          <Modal
            destroyOnClose={true}
            title={null}
            visible={showVerifyModal}
            onCancel={closeVerifyModal}
            footer={null}
            closable={true}
          >
            {!showPreview ? (
              <div className="d-flex flex-column align-items-center">
                <Webcam
                  audio={false}
                  height={371}
                  ref={webcamRef}
                  screenshotFormat="image/jpeg"
                  width={371}
                  videoConstraints={videoConstraints}
                />
                <button
                  onClick={capture}
                  className="btn btn-red mt-4 px-sm-4 py-2"
                >
                  Capture photo
                </button>
              </div>
            ) : (
              <div className="d-flex flex-column align-items-center">
                <img
                  src={image}
                  alt="facial verification"
                  className="preview_image"
                />
                {loading_facial && <p>This may take a while...</p>}
                <div className="d-flex gap-4">
                  <button
                    disabled={loading_facial}
                    onClick={() => setShowPreview(false)}
                    className="btn  mt-4 px-sm-4 py-2"
                  >
                    Try Again
                  </button>
                  <button
                    disabled={loading_facial}
                    className="btn btn-red mt-4 px-sm-4 py-2"
                    onClick={submitFacialVerification}
                  >
                    Submit {loading_facial && <Spin />}
                  </button>
                </div>
              </div>
            )}
          </Modal>
        </div>
      </div>
    </section>
  );
}
